import React from "react";

export default (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 21.6151C16.9706 21.6151 21 17.5857 21 12.6151C21 7.64455 16.9706 3.61511 12 3.61511C7.02944 3.61511 3 7.64455 3 12.6151C3 17.5857 7.02944 21.6151 12 21.6151Z"
      fill="#5FCB8A"
    />
    <path
      d="M15.5 11.1151L11.3426 15.2726L9.1851 13.1151M21 12.6151C21 17.5857 16.9706 21.6151 12 21.6151C7.02944 21.6151 3 17.5857 3 12.6151C3 7.64455 7.02944 3.61511 12 3.61511C16.9706 3.61511 21 7.64455 21 12.6151Z"
      stroke="#5FCB8A"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5 11.1151L11.3425 15.2726L9.18506 13.1151"
      stroke="white"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
