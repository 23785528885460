import React, { forwardRef, ReactNode } from 'react';
import { Scrollbars, positionValues } from 'react-custom-scrollbars-2';
import styled from 'styled-components';
import { Color } from '../../constants/ColorEnum';
import { ColorTheme } from './Tooltip';

export enum ScrollAreaSize {
    S = 'S',
    L = 'L',
    XL = 'XL',
}

type ScrollAreaSizeKeys = keyof typeof ScrollAreaSize | string;

interface ScrollAreaSizeInterface {
    size: number;
}

type ScrollAreaValuesType = {
    [key in ScrollAreaSizeKeys]: ScrollAreaSizeInterface;
};

export const ScrollAreaValues: ScrollAreaValuesType = {
    [ScrollAreaSize.S]: {
        size: 6,
    },
    [ScrollAreaSize.L]: {
        size: 10,
    },
    [ScrollAreaSize.XL]: {
        size: 16,
    },
};

export type ScrollAreaProps = {
    size: ScrollAreaSize;
    children: ReactNode;
    autoHide?: boolean;
    /** used if autoHide is set to true */
    autoHideTimeout?: number;
    /** used if autoHide is set to true */
    autoHideDuration?: number;
    autoHeight?: boolean;
    /** used if autoHeight is set to true */
    autoHeightMin?: number | string;
    /** used if autoHeight is set to true */
    autoHeightMax?: number | string;
    hideHorizontalTrack?: boolean;
    hideVerticalTrack?: boolean;
    renderScrollbarHorizontal?: any;
    renderThumbHorizontal?: any;
    onScrollFrame?: (values: positionValues) => void;
    onUpdate?: (values: positionValues) => void;
    onScrollStart?: () => void;
    onScrollStop?: () => void;
    onScroll?: () => void;
    verticalTrackRightPosition?: number;
    theme?: ColorTheme;
    /** used for unit test */
    dataTestId?: string;
};

const Track = styled.div<ScrollAreaProps>`
    background-color: transparent;
    border-radius: 8px;
    position: absolute;
    transition: opacity 0.1s ease-in-out;
`;

const HorizontalTrack = styled(Track)`
    right: 0;
    bottom: 0;
    left: 0;
`;

const VerticalTrack = styled(Track)<ScrollAreaProps>`
    bottom: 0;
    top: 0;
    right: ${({ verticalTrackRightPosition }) => verticalTrackRightPosition || 0}px;
    ${({ size }) => (size === ScrollAreaSize.XL ? 'padding: 4px;' : '')}
`;

const Thumb = styled.div<{ theme: ColorTheme }>`
    background-color: ${({ theme }) => (theme === ColorTheme.LIGHT ? Color.PRIMARY10 : Color.SECONDARY10)};
    cursor: default;
    position: relative;
    border-radius: inherit;
    transition: background-color 0.1s ease-in-out;
    :hover,
    :active {
        background-color: ${({ theme }) => (theme === ColorTheme.LIGHT ? Color.PRIMARY20 : Color.SECONDARY20)};
    }
`;
export const ScrollArea = forwardRef<Scrollbars, ScrollAreaProps>(
    (
        {
            size,
            children,
            autoHide = false,
            autoHideTimeout = undefined,
            autoHideDuration = undefined,
            autoHeight = false,
            autoHeightMin = undefined,
            autoHeightMax = undefined,
            onScrollFrame = undefined,
            onUpdate = undefined,
            hideHorizontalTrack = false,
            hideVerticalTrack = false,
            verticalTrackRightPosition = undefined,
            theme = ColorTheme.LIGHT,
            onScrollStart = undefined,
            onScrollStop = undefined,
            onScroll = undefined,
            dataTestId = undefined,
        }: ScrollAreaProps,
        ref,
    ) => {
        function getHorizontalTrack({ style, ...props }: any) {
            return (
                <HorizontalTrack
                    size={size}
                    children={children}
                    {...props}
                    style={{ ...style, height: `${ScrollAreaValues[size].size}px` }}
                    data-scroll-track
                />
            );
        }
        function getVerticalTrack({ style, ...props }: any) {
            return (
                <VerticalTrack
                    size={size}
                    children={children}
                    verticalTrackRightPosition={verticalTrackRightPosition}
                    {...props}
                    style={{ ...style, width: `${ScrollAreaValues[size].size}px` }}
                    data-scroll-track
                />
            );
        }

        function getThumb(props: any) {
            return <Thumb theme={theme} {...props} data-scroll-thumb />;
        }

        function getViewContainer({ style, ...props }: any) {
            let styles = {};
            if (hideHorizontalTrack) {
                styles = { ...styles, overflowX: 'hidden', marginBottom: 0 };
            }
            if (hideVerticalTrack) {
                styles = { ...styles, overflowY: 'hidden', marginRight: 0 };
            }
            return <div {...props} style={{ ...style, ...styles }} />;
        }

        return (
            <Scrollbars
                onScrollFrame={onScrollFrame}
                onUpdate={onUpdate}
                autoHide={autoHide}
                autoHideTimeout={autoHideTimeout}
                autoHideDuration={autoHideDuration}
                autoHeight={autoHeight}
                autoHeightMin={autoHeightMin}
                autoHeightMax={autoHeightMax}
                // @ts-ignore
                renderTrackHorizontal={hideHorizontalTrack ? (props) => <div {...props} /> : getHorizontalTrack}
                // @ts-ignore
                renderTrackVertical={hideVerticalTrack ? (props) => <div {...props} /> : getVerticalTrack}
                renderThumbHorizontal={getThumb}
                renderThumbVertical={getThumb}
                renderView={getViewContainer}
                onScrollStart={onScrollStart}
                onScrollStop={onScrollStop}
                onScroll={onScroll}
                ref={ref}
                data-testid={dataTestId}
            >
                {children}
            </Scrollbars>
        );
    },
);
