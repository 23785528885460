import React from "react";

export default (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.4">
      <path
        d="M21.5 12.6151C21.5 17.5857 17.4706 21.6151 12.5 21.6151C7.52944 21.6151 3.5 17.5857 3.5 12.6151C3.5 7.64455 7.52944 3.61511 12.5 3.61511C17.4706 3.61511 21.5 7.64455 21.5 12.6151Z"
        stroke="#364159"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.40499 14.0901C9.44031 13.9136 9.45796 13.8253 9.49025 13.743C9.51891 13.6699 9.55608 13.6005 9.60098 13.5361C9.65157 13.4635 9.71523 13.3999 9.84255 13.2726L14 9.11511C14.5523 8.56283 15.4477 8.56283 16 9.11511C16.5523 9.6674 16.5523 10.5628 16 11.1151L11.8426 15.2726C11.7152 15.3999 11.6516 15.4635 11.579 15.5141C11.5147 15.559 11.4452 15.5962 11.3721 15.6249C11.2898 15.6571 11.2015 15.6748 11.025 15.7101L9 16.1151L9.40499 14.0901Z"
        stroke="#364159"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
