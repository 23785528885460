
import React from 'react';

export default (
    <svg width="91" height="45" viewBox="0 0 91 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.2" d="M3.3849 2.65629C13.1441 3.88536 20.6924 12.215 20.6924 22.3076C20.6924 32.4002 13.1441 40.7298 3.3849 41.9589L3.3849 2.65629Z" fill="#233447" stroke="white" strokeWidth="5"/>
        <path opacity="0.4" d="M25.6925 2.65628C35.4517 3.88536 43 12.215 43 22.3076C43 32.4002 35.4517 40.7298 25.6925 41.9589L25.6925 2.65628Z" fill="#233447" stroke="white" strokeWidth="5"/>
        <path opacity="0.6" d="M48 2.65628C57.7591 3.88536 65.3075 12.215 65.3075 22.3076C65.3075 32.4002 57.7591 40.7298 48 41.9589L48 2.65628Z" fill="#233447" stroke="white" strokeWidth="5"/>
        <path d="M70.3076 2.65628C80.0668 3.88536 87.6151 12.215 87.6151 22.3076C87.6151 32.4002 80.0668 40.7298 70.3076 41.9589L70.3076 2.65628Z" fill="#233447" stroke="white" strokeWidth="5"/>
    </svg>
);
