import React from "react";

export default (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none">
    <rect x="3" y="3" width="12" height="12" rx="1" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 1H14C15.6569 1 17 2.34315 17 4V14C17 15.6569 15.6569 17 14 17H4C2.34314 17 1 15.6569 1 14V4C1 2.34314 2.34315 1 4 1ZM4 3C3.44772 3 3 3.44772 3 4V14C3 14.5523 3.44772 15 4 15H14C14.5523 15 15 14.5523 15 14V4C15 3.44772 14.5523 3 14 3H4Z"
      fill="#1C1C1F"
      fillOpacity="0.2"
    />
    <path
      d="M5 9C5 8.44772 5.44772 8 6 8H12C12.5523 8 13 8.44772 13 9C13 9.55228 12.5523 10 12 10H6C5.44772 10 5 9.55228 5 9Z"
      fill="#596FFF"
    />
  </svg>
);
