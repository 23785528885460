import React, { PropsWithChildren } from "react";
import styled, { css } from "styled-components";
import { Color } from "../../constants/ColorEnum";
import LineHeight from "../../constants/LineHeight";
import TextAlign from "../../constants/TextAlign";
import { TextSize } from "../../constants/TextSize";
import TextStyle from "../../constants/TextStyle";
import { TextWeight } from "../../constants/TextWeight";
import { WhiteSpace } from "../../constants/WhiteSpace";

export type TextProps = {
  as?: "span" | "label";
  htmlFor?: string;
  size?: TextSize;
  weight?: TextWeight;
  style?: TextStyle;
  align?: TextAlign;
  whiteSpace?: WhiteSpace;
  color?: Color;
  uppercase?: boolean;
  underline?: boolean;
  ellipsis?: boolean;
  className?: string;
  title?: string;
  dataTestid?: string;
  lineClamp?: number;
  maxWidth?: string;
  strikeThrough?: boolean;
  breakWords?: boolean;
};

type StyledTextProps = {
  as?: "span" | "label";
  htmlFor?: string;
  textSize?: TextSize;
  weight?: TextWeight;
  textStyle?: TextStyle;
  textColor?: Color;
  whiteSpace?: WhiteSpace;
  uppercase?: boolean;
  underline?: boolean;
  ellipsis?: boolean;
  className?: string;
  title?: string;
  dataTestid?: string;
  lineClamp?: number;
  maxWidth?: string;
  strikeThrough?: boolean;
  breakWords?: boolean;
  textAlign?: string;
};

const StyledText = styled.span<StyledTextProps>`
  display: inline-block;
  font-size: ${({ textSize }) => `${textSize}px`};
  font-weight: ${({ weight }) => weight};
  color: ${({ textColor }) => textColor};
  cursor: ${({ htmlFor }) => (htmlFor ? "pointer" : "inherit")};
  text-transform: ${({ uppercase }) => (uppercase ? "uppercase" : "none")};
  text-decoration-line: ${({ underline }) =>
    underline ? "underline" : "none"};
  text-decoration: ${({ strikeThrough }) =>
    strikeThrough ? "line-through" : "none"};
  max-width: ${({ maxWidth }) => maxWidth || "none"};
  word-break: ${({ breakWords }) => (breakWords ? "break-word" : "normal")};
  transition: color 0.1s ease-in-out;
  line-height: ${({ textSize }) =>
    textSize ? LineHeight[textSize] : LineHeight[TextSize.M]};
  ${({ textAlign }) => textAlign && `text-align: ${textAlign};`}
  ${({ textStyle }) => textStyle && `font-style: ${textStyle};`}
    ${({ ellipsis, lineClamp }) =>
    ellipsis
      ? (lineClamp &&
          css`
            display: -webkit-box;
            -webkit-line-clamp: ${lineClamp};
            -webkit-box-orient: vertical;
            overflow: hidden;
          `) ||
        css`
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          display: block;
        `
      : ""};
  white-space: ${({ whiteSpace }) => whiteSpace};
`;

/**
 * This component is used to add text in the application
 */
export function Text({
  as = "span",
  htmlFor,
  children,
  size = TextSize.M,
  weight = TextWeight.REGULAR,
  color = Color.PRIMARY,
  style,
  align,
  uppercase = false,
  underline = false,
  ellipsis = false,
  lineClamp,
  className,
  title,
  maxWidth = "",
  dataTestid,
  strikeThrough,
  whiteSpace = WhiteSpace.NORMAL,
  breakWords = false,
  ...props
}: PropsWithChildren<TextProps>) {
  return (
    <StyledText
      as={as}
      htmlFor={htmlFor}
      textSize={size}
      weight={weight}
      textColor={color}
      textStyle={style}
      textAlign={align}
      title={title}
      uppercase={uppercase}
      underline={underline}
      ellipsis={ellipsis}
      className={className}
      lineClamp={lineClamp}
      maxWidth={maxWidth}
      data-testid={dataTestid}
      strikeThrough={strikeThrough}
      whiteSpace={whiteSpace}
      breakWords={breakWords}
      {...props}
    >
      {children}
    </StyledText>
  );
}
