import React, { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

export enum FlexDirection {
    ROW = 'row',
    ROW_REVERSE = 'row-reverse',
    COLUMN = 'column',
    COLUMN_REVERSE = 'column-reverse',
}

export enum FlexAlignItems {
    NORMAL = 'normal',
    FLEX_START = 'flex-start',
    FLEX_END = 'flex-end',
    CENTER = 'center',
    BASELINE = 'baseline',
    INHERIT = 'inherit',
    STRETCH = 'stretch',
}

export enum FlexJustifyContent {
    NORMAL = 'normal',
    FLEX_START = 'flex-start',
    FLEX_END = 'flex-end',
    CENTER = 'center',
    SPACE_BETWEEN = 'space-between',
    SPACE_AROUND = 'space-around',
    INHERIT = 'inherit',
}

export enum FlexWrap {
    NO_WRAP = 'nowrap',
    WRAP = 'wrap',
    WRAP_REVERSE = 'wrap-reverse',
    INHERIT = 'inherit',
}

export type FlexBoxWrapperStyleProps = {
    distance?: string;
    flexWrap?: FlexWrap;
    width?: string;
    height?: string;
    minWidth?: string;
    maxWidth?: string;
};

export type FlexBoxStyleProps = FlexBoxWrapperStyleProps & {
    flexDirection?: FlexDirection;
    alignItems: FlexAlignItems;
    justifyContent: FlexJustifyContent;
    overflow?: string;
    marginTop?: string;
    marginRight?: string;
    marginBottom?: string;
    marginLeft?: string;
    textAlign?: string;
};

const StyledWrapper = styled.div<FlexBoxWrapperStyleProps>`
    min-width: ${({ minWidth }) => minWidth || ''};
    width: ${({ width }) => width || 'auto'};
    height: ${({ height }) => height || 'auto'};
    ${({ distance, flexWrap }) =>
        (flexWrap === FlexWrap.WRAP || flexWrap === FlexWrap.WRAP_REVERSE) && distance !== '0'
            ? css`
                  margin: ${`-${distance}`} 0 0 ${`-${distance}`};
              `
            : ''}
`;

const StyledFlexBox = styled.div<FlexBoxStyleProps>`
    min-width: ${({ minWidth }) => minWidth || ''};
    max-width: ${({ maxWidth }) => maxWidth || ''};
    width: ${({ width }) => width || 'auto'};
    height: ${({ height }) => height || 'auto'};
    display: flex;
    flex-direction: ${({ flexDirection }) => flexDirection};
    align-items: ${({ alignItems }) => alignItems};
    justify-content: ${({ justifyContent }) => justifyContent};
    flex-wrap: ${({ flexWrap }) => flexWrap};
    overflow: ${({ overflow }) => overflow};
    text-align: ${({ textAlign }) => textAlign};

    margin: ${({ marginTop, marginRight, marginBottom, marginLeft }) =>
        `${marginTop} ${marginRight} ${marginBottom} ${marginLeft}`};

    > * {
        ${({ flexDirection, distance, flexWrap }) => {
        if (flexWrap === FlexWrap.WRAP || flexWrap === FlexWrap.WRAP_REVERSE) {
            return css`
                    margin: ${distance} 0 0 ${distance};
                `;
        }
        switch (flexDirection) {
            case FlexDirection.ROW:
                return css`
                        &:not(:last-child) {
                            margin-right: ${distance};
                        }
                    `;
            case FlexDirection.ROW_REVERSE:
                return css`
                        &:not(:first-child) {
                            margin-right: ${distance};
                        }
                    `;
            case FlexDirection.COLUMN:
                return css`
                        &:not(:last-child) {
                            margin-bottom: ${distance};
                        }
                    `;
            case FlexDirection.COLUMN_REVERSE:
                return css`
                        &:not(:first-child) {
                            margin-bottom: ${distance};
                        }
                    `;
            default:
                return '';
        }
    }}
    }
`;

export type FlexBoxProps = {
    direction?: FlexDirection;
    wrap?: FlexWrap;
    alignItems?: FlexAlignItems;
    justifyContent?: FlexJustifyContent;
    distance?: string;
    marginTop?: string;
    marginRight?: string;
    marginBottom?: string;
    marginLeft?: string;
    overflow?: string;
    width?: string;
    height?: string;
    dataTestid?: string;
    minWidth?: string;
    maxWidth?: string;
    className?: string;
    textAlign?: string;
};

/**
 * Flex Box renders a container for a flexible box layout.
 *
 * The Flexible Box Layout Module, makes it easier to design flexible responsive layout structure without using float or positioning.
 */
export const FlexBox = ({
    direction = FlexDirection.ROW,
    wrap = FlexWrap.NO_WRAP,
    alignItems = FlexAlignItems.NORMAL,
    justifyContent = FlexJustifyContent.NORMAL,
    distance = '0',
    marginTop = '0',
    marginRight = '0',
    marginBottom = '0',
    marginLeft = '0',
    overflow,
    children,
    width,
    height,
    dataTestid,
    minWidth,
    maxWidth,
    className,
    textAlign,
}: PropsWithChildren<FlexBoxProps>) => (
    <StyledWrapper
        data-testid={dataTestid}
        distance={distance}
        flexWrap={wrap}
        width={width}
        height={height}
        minWidth={minWidth}
        maxWidth={maxWidth}
        className={className}
    >
        <StyledFlexBox
            flexDirection={direction}
            flexWrap={wrap}
            alignItems={alignItems}
            justifyContent={justifyContent}
            distance={distance}
            overflow={overflow}
            width={width}
            height={height}
            marginTop={marginTop}
            marginRight={marginRight}
            marginBottom={marginBottom}
            marginLeft={marginLeft}
            minWidth={minWidth}
            maxWidth={maxWidth}
            textAlign={textAlign}
        >
            {children}
        </StyledFlexBox>
    </StyledWrapper>
);
