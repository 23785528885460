import React from "react";

export default (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 15.1151L14.5 10.6151M10 10.6151L14.5 15.1151M21 12.6151C21 17.5857 16.9706 21.6151 12 21.6151C7.02944 21.6151 3 17.5857 3 12.6151C3 7.64455 7.02944 3.61511 12 3.61511C16.9706 3.61511 21 7.64455 21 12.6151Z"
      stroke="#AFB3BD"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
