import React from "react";
import styled, { keyframes } from "styled-components";

const motion = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.2;
    }
    100% {
        opacity: 1;
    }
`;

const Wrapper = styled.div<{size: number, sizeUnit?: string}>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${props => `${props.size}${props.sizeUnit}`};
    height: ${props => `${props.size / 2.5}${props.sizeUnit}`};
`;

const Cube = styled.div<{size: number, sizeUnit?: string, color: string, x: number, y: number, index: number}>`
    position: absolute;
    top: ${props => `${props.y}${props.sizeUnit}`};
    left: ${props => `${props.x}${props.sizeUnit}`};
    width: ${props => `${props.size / 5}${props.sizeUnit}`};
    height: ${props => `${props.size / 2.5}${props.sizeUnit}`};
    background-color: ${props => props.color};
    animation: ${motion} 1.5s cubic-bezier(0.895, 0.03, 0.685, 0.22) infinite;
    animation-delay: ${props => props.index * 0.2}s;
    border-radius: ${props => `0 ${props.size / 5}${props.sizeUnit} ${props.size / 5}${props.sizeUnit} 0`};
`;

type GetCupesProps = {
    countCubeInLine: number, color: string, size: number, sizeUnit: string
};

type WrapperProps = {
    size: number, color: string, loading?: boolean, sizeUnit: string,
};

export const RewindLoader = ({ size, color, sizeUnit }: WrapperProps) => {
    const countCubeInLine = 4;
    
    return (
        <Wrapper size={size} sizeUnit={sizeUnit}>
            {getCubes({ countCubeInLine, color, size, sizeUnit })}
        </Wrapper>
    );
};


const getCubes = ({ countCubeInLine, color, size, sizeUnit }: GetCupesProps) => {
    const arr = Array.from({length: countCubeInLine}, (_, i) => i + 1)
    return (
        <>
            {arr.map((e, i) => <Cube
                    color={color}
                    size={size}
                    x={i * (size / 4 + size / 20)}
                    y={0}
                    key={i}
                    index={i}
                    sizeUnit={sizeUnit}
                />)
            }
        </>
    )

};
